<template>
    <div class="container container-tight">
        <div class="container">
            <h1 class="page-title">{{ $t("title") }}</h1>
        </div>
        <section class="section mb-xs" v-if="!success">
            <div class="container">
                <div class="panel">
                    <div class="panel-body">
                        <i18n path="help_text.text01" tag="p">
                            <template v-slot:link>
                                <a
                                    :href="$t('link.faq')"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >{{ $t("help_text.help_center_link") }}</a
                                >
                            </template>
                        </i18n>
                        <p>{{ $t("help_text.text02") }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section" v-if="!success">
            <div class="container">
                <LoaderWrapper radius="80px">
                    <div class="panel">
                        <div class="panel-body">
                            <form @submit.prevent="send" class="form-inline form">
                                <BaseInput
                                    :label="$t('label.full_name')"
                                    v-model="contact.name"
                                    name="name"
                                    type="text"
                                    required
                                ></BaseInput>
                                <div :class="{ 'form-error': this.error.contact }">
                                    <BaseInput
                                        :label="$t('label.email')"
                                        v-model="contact.email"
                                        type="email"
                                        name="email"
                                        required
                                    ></BaseInput>
                                    <p class="helper-block error" v-if="this.error.contact">
                                        {{ $t("error.invalid_email") }}
                                    </p>
                                </div>
                                <BaseInput
                                    :label="$t('label.subject')"
                                    v-model="contact.subject"
                                    type="text"
                                    name="subject"
                                    required
                                ></BaseInput>
                                <BaseInput
                                    class="mb-0"
                                    :label="$t('label.message')"
                                    v-model="contact.message"
                                    type="textarea"
                                    name="message"
                                    required
                                ></BaseInput>
                                <p class="mt-0 ts-quiet">{{ $t("message_note") }}</p>
                                <BaseInput
                                    :label="$t('label.device')"
                                    v-model="contact.device"
                                    type="text"
                                    name="device"
                                ></BaseInput>
                                <div :class="{ 'form-error': this.error.submit }">
                                    <p class="helper-block error" v-if="this.error.submit">
                                        {{ $t("error.contact_error") }}
                                    </p>
                                </div>

                                <div class="ts-align-right">
                                    <BaseButton
                                        type="submit"
                                        class="button  button-primary"
                                        :loading="loading"
                                    >
                                        {{ $t("button.send") }}
                                    </BaseButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </LoaderWrapper>
            </div>
        </section>

        <section class="section" v-if="success">
            <div class="container">
                <div class="card">
                    <h3>{{ $t("thanks.title") }}</h3>
                    <p class="ts-quiet">
                        {{ $t("thanks.text") }}
                    </p>
                    <p class="ts-quiet" v-html="$t('thanks.last')"></p>

                    <p class="ts-quiet ts-small mb-3">
                        {{ $t("thanks.note") }}
                    </p>
                    <div class="ts-align-center">
                        <i18n-link class="button  button-primary" :to="{ name: 'home' }">{{
                            $t("button.back_to_home")
                        }}</i18n-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import LoaderWrapper from "../components/LoaderWrapper"
import { getHeaders } from "../utils"
import { load } from "recaptcha-v3"

export default {
    components: {
        LoaderWrapper,
    },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.title"),
            description: this.$i18n.t("meta.description"),
        })
    },
    i18n: {
        messages: {
            en: {
                meta: {
                    title: "Contact Us",
                    description:
                        "Should you have any questions about Soracom Mobile, feel free to contact us at any time.",
                },
                title: "Contact",
                label: {
                    full_name: "Name",
                    email: "Email",
                    subject: "Subject",
                    message: "Message",
                    device: "Your device model",
                },
                error: {
                    invalid_email: "Please enter a valid Email.",
                    contact_error:
                        "If you fail to connect after trying several times, can you please contact us at support@soracommobile.com",
                },
                button: { send: "Send", back_to_home: "Go back to Home" },
                message_note: "* Please provide as much detail as possible.",
                thanks: {
                    title: "Thank you for your contact!",
                    text:
                        "Our customer support team will check your inquiry and forward it to the best person when necessary. We'll get back to you as soon as we can.",
                    last: "Soracom Mobile Customer Support",
                    note:
                        "* If you do not receive our reply in 2 days, please contact us again as there might be a system problem.",
                },
                help_text: {
                    text01: "See also {link} before you sent an inquiry.",
                    help_center_link: "our FAQ page",
                    text02: "Business hour: Weekdays 10:00 - 18:00 JST (GMT+09:00)",
                },
            },
            ja: {
                meta: {
                    title: "お問い合わせ",
                    description:
                        "ソラコム モバイルに関する質問はこちらのお問い合わせページからお送りください。",
                },
                title: "お問い合わせ",
                label: {
                    full_name: "お名前",
                    email: "メールアドレス",
                    subject: "件名",
                    message: "メッセージ",
                    device: "利用端末",
                },
                error: {
                    invalid_email: "有効なメールアドレスを入力してください。",
                    contact_error:
                        "お問い合わせ中にエラーが発生しました。このエラーをみかけた場合は、 support@soracommobile.com までご連絡ください。",
                },
                button: { send: "送信", back_to_home: "トップへ戻る" },
                message_note:
                    "* より的確な対応を行えるよう、できる限り詳細な情報をご提供ください。",
                thanks: {
                    title: "お問い合わせありがとうございます",
                    text: "お問い合わせ内容を確認後、回答いたしますのでしばらくお待ちください。",
                    last: "ソラコム モバイル カスタマーサポート",
                    note:
                        "* もし2日以内に返信がない場合、システムに障害が発生していた可能性がありますので、お手数ですが再度お問い合わせをお願いいたします。",
                },
                help_text: {
                    text01: "「{link}」ページもあわせてご確認ください。",
                    help_center_link: "よくある質問",
                    text02:
                        "平日 10:00 - 18:00 （日本時間）の対応となります。問い合わせ状況や内容によっては、回答までにお時間をいただく場合があります。",
                },
            },
        },
    },
    methods: {
        async send() {
            this.error.submit = false
            if (!this.validateEmail()) {
                this.error.contact = true
                return
            } else {
                this.error.contact = false
            }

            this.loading = true

            try {
                /*const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_PUBLIC, {
                    useRecaptchaNet: true,
                })
                const token = await recaptcha.execute("contact")*/
                const response = await fetch(`${this.$apiURL}/public/contact`, {
                    method: "POST",
                    body: JSON.stringify({ ...this.contact }),
                    headers: getHeaders(),
                })

                const result = await response.json()

                if (result.message === "Success") {
                    this.loading = false
                    this.success = true
                    this.contact = {
                        subject: "",
                        message: "",
                        email: "",
                        name: "",
                        device: "",
                        locale: this.$i18n.locale,
                    }
                } else {
                    this.success = false
                    this.loading = false
                    this.error.submit = true
                }
            } catch (e) {
                console.log(e.toString())
            }
        },
        validateEmail() {
            const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
            this.errorEmail =
                !expression.test(String(this.contact.email).toLowerCase()) ||
                this.contact.email.slice(-4) === ".con"
            return !this.errorEmail
        },
    },
    mounted() {
        // Receive user information from App
        this.contact.name = this.$route.query.name ? this.$route.query.name : ""
        this.contact.email = this.$route.query.email ? this.$route.query.email : ""
    },
    data() {
        return {
            contact: {
                subject: "",
                message: "",
                email: "",
                name: "",
                device: "",
                locale: this.$i18n.locale,
            },
            error: {
                contact: false,
                submit: false,
            },
            success: false,
            loading: false,
        }
    },
}
</script>

<style>
.mb-xs {
    margin-bottom: 20px;
}
.mt-xs {
    margin-top: 20px;
}
</style>
