<template>
    <div>
        <transition name="fade-slow" mode="out-in">
            <div v-if="loading" class="loading-wrapper">
                <div class="container">
                    <div class="loader">
                        <div class="loader--dot"></div>
                        <div class="loader--dot"></div>
                        <div class="loader--dot"></div>
                        <div class="loader--dot"></div>
                        <div class="loader--dot"></div>
                        <div class="loader--dot"></div>
                        <div class="loader--text"></div>
                    </div>
                </div>
            </div>
        </transition>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "LoaderWrapper",
    props: {
        loading: { type: Boolean, default: false },
        radius: { type: String, default: "20px" },
    },
}
</script>

<style scoped>
.card-body {
    position: relative;
}
.loading-wrapper {
    background-color: rgba(0, 0, 0, 0.35);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}
.container {
    height: 100vh;
    width: 100vw;
    color: white;
}

.loader {
    height: 20px;
    width: 250px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.loader--dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;
}
.loader--dot:first-child {
    background-color: #ffffff;
    animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
    background-color: #eaeaeaea;
    animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
    background-color: #aeaeaeba;
    animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
    background-color: #7979798a;
    animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
    background-color: #3030305c;
    animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
    background-color: #000000;
    animation-delay: 0s;
}
.loader--text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;
}
.loader--text:after {
    content: "Processing";
    font-weight: bold;
    animation-name: loading-text;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes loader {
    15% {
        transform: translateX(0);
    }
    45% {
        transform: translateX(230px);
    }
    65% {
        transform: translateX(230px);
    }
    95% {
        transform: translateX(0);
    }
}
@keyframes loading-text {
    0% {
        content: "Processing";
    }
    25% {
        content: "Processing.";
    }
    50% {
        content: "Processing..";
    }
    75% {
        content: "Processing...";
    }
}
</style>
